<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-3">
     
        <select-clinicas  :autoBlock="false" consultorio class="vs__sm" v-model="$v.agenda.clinica_id.$model" :valueConsultorio="agenda.consultorio" @consultorio="agenda.consultorio = $event" />
        <b-form-invalid-feedback
          :force-show="
            typeof validateState('clinica_id') == 'boolean' &&
              !validateState('clinica_id')
          "
        >
          Selecione a Clinica
        </b-form-invalid-feedback>
      </div>

      <!-- <div class="col-12 col-md-6 col-lg-3 ">
        <label>Especialiade</label>
        <v-select
          class="vs__sm"
          @input="carregarMedicos()"
          :options="especialidades"
          label="especialidade"
          :reduce="(especialidades) => especialidades.id"
          v-model="$v.agenda.especialidade.$model"
        >
          <template #search="{ attributes, events }">
            <input
              :class="[
                'vs__search',
                typeof validateState('especialidade') == 'boolean' &&
                !validateState('especialidade')
                  ? 'isInvalid'
                  : '',
              ]"
              v-bind="attributes"
              v-on="events"
            />
          </template>
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              Nenhum resultado para <em>{{ search }}</em
              >.
            </template>
            <em style="opacity: 0.5" v-else
              >Comece a digitar para procurar uma especialidade.</em
            >
          </template>
        </v-select>
        <b-form-invalid-feedback
          :force-show="
            typeof validateState('especialidade') == 'boolean' &&
              !validateState('especialidade')
          "
        >
          Selecione a Especialidade
        </b-form-invalid-feedback>
      </div>

      <div class="col-12 col-md-6 col-xl-3 ">
        <label>Médico</label>
        <v-select
          class="vs__sm"
          :loading="loadingMedicos"
          :options="medicos"
          label="razao_social"
          :reduce="(medicos) => medicos.id"
          v-model="$v.agenda.entidade_id.$model"
        >
          <template #search="{ attributes, events }">
            <input
              :class="[
                'vs__search',
                typeof validateState('entidade_id') == 'boolean' &&
                !validateState('entidade_id')
                  ? 'isInvalid'
                  : '',
              ]"
              v-bind="attributes"
              v-on="events"
            />
          </template>
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              Nenhum resultado para <em>{{ search }}</em
              >.
            </template>
            <em style="opacity: 0.5" v-else
              >Não encontramos nenhum médico com essa especialiade 😕.</em
            >
          </template>
        </v-select>
        <b-form-invalid-feedback
          :force-show="
            typeof validateState('entidade_id') == 'boolean' &&
              !validateState('entidade_id')
          "
        >
          Selecione o Médico
        </b-form-invalid-feedback>
      </div> -->

      <div class="col-12 col-md-6 cold-lg-3">
        <select-medico-especialidade
          class="vs__sm"
          :medico_id="$v.agenda.entidade_id.$model"
          @medico="$v.agenda.entidade_id.$model = $event"
          :especialidade_id="$v.agenda.especialidade.$model"
          @especialidade="$v.agenda.especialidade.$model = $event"
          :stateMedico="
            typeof validateState('entidade_id') == 'boolean' &&
              !validateState('entidade_id')
          "
          :stateEspecialidade="
            typeof validateState('especialidade') == 'boolean' &&
              !validateState('especialidade')
          "
        />
      </div>

      <div class="col-12 col-md-6 col-lg-3">
        <label>Data</label>
        <b-form-input
          v-model="$v.agenda.data.$model"
          :state="validateState('data')"
          type="date"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback>
          Selecione uma Data
        </b-form-invalid-feedback>
      </div>
    </div>
    <div class="d-flex flex-wrap mt-2">
      <div class="mr-3">
        <b-btn variant="primary" @click="selectAllRows" size="sm">
          <i class="fas fa-check-square mr-1"></i> Selecionar Todos</b-btn
        >
      </div>

      <div class="">
        <b-form-checkbox
          :value="1"
          :unchecked-value="0"
          v-model="agenda.manterHorarios"
          class=""
        >
          Manter Horários
        </b-form-checkbox>
      </div>
    </div>

    <b-table
      ref="tableHorarios"
      class="mt-3 mb-0"
      outlined
      small
      stacked="md"
      :fields="campos"
      :items="horarios"
      selectable
      responsive="lg"
      select-mode="multi"
      @row-selected="onRowSelected($event)"
    >
      <template #cell(confirmou)="row">
        <i class="fas fa-check" v-if="row.item.confirmou"> </i>
        <i class="fas fa-times" v-else> </i>
      </template>

      <template #cell(retorno)="row">
        <i class="fas fa-check" v-if="row.item.retorno"> </i>
        <i class="fas fa-times" v-else> </i>
      </template>

      <template #cell(compareceu)="row">
        <i class="fas fa-check" v-if="row.item.compareceu"> </i>
        <i class="fas fa-times" v-else> </i>
      </template>

      <template #cell(actions)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Selected</span>
        </template>
      </template>
    </b-table>
    <b-form-invalid-feedback
      :force-show="
        typeof validateState('horarios') == 'boolean' &&
          !validateState('horarios')
      "
    >
      Selecione ao menos um horário!
    </b-form-invalid-feedback>
  </div>
</template>

<script>
import ClinicasLib from "@/libs/ClinicasLib.js";
import EntidadesLib from "@/libs/EntidadesLib.js";
import EspecialidadesLib from "@/libs/EspecialidadesLib.js";
import AgendasLib from "../../libs/AgendasLib";
import { validationMixin } from "vuelidate";
import { required /*, minLength*/ } from "vuelidate/lib/validators";
import SelectClinicas from "@/components/common/SelectClinicas.vue";
import SelectMedicoEspecialidade from "@/components/common/SelectMedicoEspecialidade.vue";

export default {
  components: {
    SelectClinicas,
    SelectMedicoEspecialidade,
  },
  props: {
    horarios: { type: Array, default: () => [] },
    agendaAnterior: { type: Object, default: () => {} },
  },
  mixins: [validationMixin],
  data() {
    return {
      lista: [],

      clinicas: [],
      medicos: [],
      especialidades: [],
      campos: [
        { key: "actions", label: "" },
        {
          key: "horario",
          label: "Horario",
          sortable: true,
          class: "text-center",
        },
        { key: "paciente", label: "Paciente", class: "text-center" },
        { key: "idade", label: "Idade", class: "text-center" },
        { key: "telefone", label: "Telefone", class: "text-center" },
        { key: "confirmou", label: "Confirmou", class: "text-center" },
        { key: "compareceu", label: "Compareceu", class: "text-center" },
        { key: "retorno", label: "Retorno", class: "text-center" },
        { key: "obs", label: "Obs", class: "text-center" },
        { key: "responsavel", label: "Responsável", class: "text-center" },
      ],
      agenda: {
        clinica_id: null,
        entidade_id: null,
        especialidade: null,
        data: "",
        horarios: [],
        manterHorarios: 1,
        consultorio: 0
      },
      loadingMedicos: false,
    };
  },
  validations: {
    agenda: {
      clinica_id: {
        required(){
          
          return this.agenda.consultorio? true:!!this.agenda.clinica_id
        },
      },
      especialidade: {
        required,
      },
      entidade_id: {
        required,
      },
      data: {
        required,
      },
      horarios: {
        minLength() {
          return this.agenda.horarios.length > 0;
        },
      },
    },
  },
  methods: {
    selectAllRows() {
      if (this.agenda.horarios.length > 0) {
        this.$refs.tableHorarios.clearSelected();
        return;
      }
      this.$refs.tableHorarios.selectAllRows();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.agenda[name];
      return $dirty ? !$error : null;
    },
    // resetForm() {
    //   this.form = {
    //     name: null,
    //     food: null
    //   };

    //   this.$nextTick(() => {
    //     this.$v.$reset();
    //   });
    // },
    onRowSelected(horarios) {
      let horario = horarios.map((hrs) => Object.assign({}, hrs));
      horario.forEach((_, i) => {
        delete horario[i].id_agenda;
        // delete horario[i].id;
        delete horario[i].MedicoNome;
        delete horario[i].hora;
        delete horario[i].ClinicaNome;
        delete horario[i].DataAgenda;
        delete horario[i].created_at;
        delete horario[i].updated_at;
        delete horario[i].DataAgenda;
        delete horario[i].usado;
        delete horario[i]._rowVariant;
        horario[i]._rowStatus = "added";
      });

      this.agenda.horarios = horario;
    },
    async carregarClinicas() {
      try {
        this.clinicas = await ClinicasLib.get();
      } catch (error) {
        console.log(error);
      }
    },
    async carregarMedicos() {
      try {
        this.loadingMedicos = true;
        this.medicos = (await EntidadesLib.get("medico")).entidades.filter(
          (med) => {
            return med.configuracoes.especialidades.includes(
              this.agenda.especialidade
            );
          }
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingMedicos = false;
      }
    },
    async carregarEspecialidades() {
      try {
        this.especialidades = await EspecialidadesLib.get();
      } catch (error) {
        console.log(error);
      }
    },
    async salvar() {
      this.$v.agenda.$touch();
      if (this.$v.agenda.$anyError) {
        return;
      }

      this.toastConfirmacao(
        async () => {
          try {
            this.agenda.hora_inicio = this.agendaAnterior.hora_inicio;
            this.agenda.espera_qtd = this.agendaAnterior.espera_qtd;
            this.agenda.consulta_qtd =
              this.agenda.horarios.filter((horario) => !horario.retorno)
                .length || 0;
            this.agenda.retorno_qtd =
              this.agenda.horarios.filter((horario) => horario.retorno)
                .length || 0;
            this.agenda.total = this.agenda.horarios.length;
            this.agenda.intervalo = 0;
            // console.log("agenda remarcada", this.agenda);
            if (!this.agenda.manterHorarios) {
              this.agenda.horariosApagar = this.agenda.horarios.map(h=>h.id)
            }
            this.agenda.horarios.forEach((_,i)=>{
              delete this.agenda.horarios[i].id
            })
            let result = await AgendasLib.store({
              ...this.agenda,
              id_agenda_anterior: this.agendaAnterior.id,
            });
            // console.log(result);
            if (result.success) {
              this.showToast("success", "Agenda remarcada com sucesso!");
              this.$set(this.agenda, "id", result.data[0]);
              this.$emit("remarcou", this.agenda);
            } else {
              this.showToast(
                "error",
                "Erro ao remarcar a agenda\n" + result.message
              );
            }
          } catch (error) {
            console.log(error);
            this.showToast("error", "Erro ao remarcar a agenda");
          }
        },
        () => {},
        "Remarcar horários?",
        "Tem certeza que deseja remarcar os horários? \nSerá gerada uma nova agenda com essas informações.",
        "question",
        "Sim, remarcar."
      );
    },
  },
  async mounted() {
    await this.carregarEspecialidades();

    await this.carregarClinicas();
  },
};
</script>

<style scoped>
/* .sm{
  height:22px;
} */
</style>
